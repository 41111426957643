import React from 'react';

import styles from './styles/seasonal-event-countdown-item.css';

interface SeasonalEventCountdownItemProps {
  value?: string;
  label?: string;
  className?: string;
}

const SeasonalEventCountdownItem: React.FC<SeasonalEventCountdownItemProps> = ({
  value,
  label,
  className,
}) => {
  const containerStyle = className && styles[className] ? className : 'container';

  return (
    <div className={styles[containerStyle]}>
      <div className={styles.value}>{value}</div>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default SeasonalEventCountdownItem;
