import React, { useEffect, useState } from 'react';

import { loadCSS } from '../../../../../utils/loadCSS';
import {
  getRemainingDays,
  getRemainingHours,
  getRemainingMinutes,
  getRemainingSeconds,
  formatLeadingZero,
} from '../../../../modules/countdownTiming';
import { useHawkWidgetContext } from '../../HawkWidget/HawkWidgetProviderContext';

import SeasonalEventCountdownItem from './SeasonalEventCountdownItem';
import styles from './styles/seasonal-event-countdown.css';

loadCSS('seasonal-event-countdown');

interface SeasonalEventCountdownProps {
  className?: string;
}

const SeasonalEventCountdown: React.FC<SeasonalEventCountdownProps> = ({ className }) => {
  const { data, translate } = useHawkWidgetContext();
  const [date, setDate] = useState(new Date());
  const [interval, storeInterval] = useState(0);

  useEffect(() => {
    // Update the current date every second - store date to force a rerender
    const interval = window.setInterval(() => {
      setDate(new Date());
    }, 1000);

    storeInterval(interval);
  }, []);

  useEffect(() => {
    // If the event end time has expired, stop the interval
    if (data && data.event) {
      if (new Date().getTime() > new Date(data.event.end_time * 1000).getTime()) {
        clearInterval(interval);
      }
    }

    return () => {
      // Remove the interval when the component is removed
      clearInterval(interval);
    };
  }, [data]);

  const containerStyle = className && styles[className] ? className : 'container';

  if (data && data.event) {
    const { event_name: eventName, end_time: endTime } = data.event;

    const days = getRemainingDays(endTime, date);
    const daysFormatted = days >= 0 ? formatLeadingZero(days) : '00';
    const hours = getRemainingHours(endTime, date);
    const hoursFormatted = hours >= 0 ? formatLeadingZero(hours) : '00';
    const minutes = getRemainingMinutes(endTime, date);
    const minutesFormatted = minutes >= 0 ? formatLeadingZero(minutes) : '00';
    const seconds = getRemainingSeconds(endTime, date);
    const secondsFormatted = seconds >= 0 ? formatLeadingZero(seconds) : '00';

    const countdownString = (): string => {
      const countdownText: string[] = [];
      if (days > 0) {
        countdownText.push(` ${daysFormatted} ${translate('seasonalEventCountdownDays')}`);
      }
      countdownText.push(` ${hoursFormatted} ${translate('seasonalEventCountdownHours')}`);
      countdownText.push(` ${minutesFormatted} ${translate('seasonalEventCountdownMinutes')}`);
      countdownText.push(` ${secondsFormatted} ${translate('seasonalEventCountdownSeconds')}`);

      return countdownText.toString();
    };

    return (
      <div className={styles[containerStyle]}>
        <div className={styles['heading']}>
          {className === 'merchantlink' ? (
            <span>{translate('seasonalEventCountdownTitle', [eventName]) + countdownString()}</span>
          ) : (
            <span>{translate('seasonalEventCountdownTitle', [eventName])}</span>
          )}
        </div>
        {className !== 'merchantlink' && (
          <div className={styles['items']}>
            {days > 0 && (
              <SeasonalEventCountdownItem
                {...{
                  value: daysFormatted,
                  label: translate('seasonalEventCountdownDays'),
                  className,
                }}
              />
            )}
            <SeasonalEventCountdownItem
              {...{
                value: hoursFormatted,
                label: translate('seasonalEventCountdownHours'),
                className,
              }}
            />
            <SeasonalEventCountdownItem
              {...{
                value: minutesFormatted,
                label: translate('seasonalEventCountdownMinutes'),
                className,
              }}
            />
            <SeasonalEventCountdownItem
              {...{
                value: secondsFormatted,
                label: translate('seasonalEventCountdownSeconds'),
                className,
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default SeasonalEventCountdown;
